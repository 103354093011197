import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  modalOpen: false,
  myTemplate: false,
  createNewTemplate: false,
  editTemplate: false,
  assignTest: false,

  modalTitle: "",
  addTestModal: false,
  searchPatient: "",
  searchNow: false,
}

const modalSlice = createSlice({
  name: "modalSlice",
  initialState: initialState,
  reducers: {
    openMyTemplate: (state, action) => {
      state.modalOpen = true;
      state.myTemplate = true;
      state.createNewTemplate = false;
      // state.showTemplate= false;
      state.editTemplate = false;
      state.assignTest = false;
      state.modalTitle = action.payload.title;
    },
    closeMyTemplate: (state, action) => {
      state.modalOpen = false;
      state.myTemplate = false;
    },
    setCreateNewTemplate: (state, action) => {
      state.createNewTemplate = true;
      state.modalOpen = true;
      state.myTemplate = false;
      // state.showTemplate = false;
      state.editTemplate = false;
      state.assignTest = false;
      state.modalTitle = action.payload.title;
    },
    setEditTemplate: (state, action) => {
      state.createNewTemplate = true;
      state.modalOpen = true;
      state.myTemplate = false;
      // state.showTemplate = false;
      state.editTemplate = true;
      state.assignTest = false;
      state.modalTitle = action.payload.title;
    },
    setAssignTest: (state, action) => {
      state.createNewTemplate = true;
      state.modalOpen = true;
      state.myTemplate = false;
      // state.showTemplate = false;
      state.editTemplate = true;
      state.assignTest = true;
      state.modalTitle = action.payload.title;
    },
    resetTemplateModal: (state, action) => {
      state.modalOpen = false;
      state.myTemplate = false;
      state.createNewTemplate = false;
      // state.showTemplate= false;
      state.editTemplate = false;
      state.assignTest = false;
      state.addTestModal = false;
      state.modalTitle = "";
    },
    setLoader: (state, action) => {
      state.loading = action.payload
    },
    setAddTestModal: (state, action) => {
      state.modalOpen = true;
      state.addTestModal = true;
      state.modalTitle = action.payload.title;
    },
    setSearchPatient: (state, action) => {
      state.searchPatient = action.payload
    },
    setSearchNow: (state, action) => {
      state.searchNow = action.payload
    }
  }
})

export const {
  openMyTemplate,
  closeMyTemplate,
  setCreateNewTemplate,
  setEditTemplate,
  setAssignTest,
  resetTemplateModal,
  setAddTestModal,
  setLoader,
  setSearchPatient,
  setSearchNow,
} = modalSlice.actions;
export default modalSlice.reducer;