import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "Who",
  hospitalId: "",
  patientId: "",
  registeredPatientData: {},
  defineLayer: true,
  whoData: [],
  contactData: [],
  choiceData: [],
  stockPermission: {},
  clinicCountryCode: {},
  cryoPermission: {},
  androPermission: {},
  dewarListData: [],
  registerDewarSelected: [],
  currentSelectedLevel: [],
  changeLocationSelectedLevel: [],
  options: [],
  selectedSliceObject: {},
  selectedSliceInputError: {
    color: "", location: "",
    //  numberOfStorage: ""
  },
  selectedCanLevelObject: {
    id: null,
    canisterName: "",
    gobletSize: null,
    quantity: null,
    dewarId: null,
  },
  selectedCanForStorageSpace: {
    containerId: null,
    containerType: "",
    numberOfStorage: null,
    dewarId: null,
  },
  canLevelAddToggle: false,
  canPackageAddToggle: false,
  canFirstLayerToggle: false,
  canLayerData: {
    id: null,
    canisterName: "",
    parentName: "",
    gobletSize: null,
    quantity: null,
    dewarId: null,
    color: "",
  },
  parentNodeDetails: [],
  nodeDetails: [],
  // hasCanisterItem: false,
  hasCanisterItem: [],
  lastSelectedLayerDetail: {},

  //Gamete-> Embryo
  procedureOocytesList: [],
  embryoDayTabCounter: 0,
  embryoExecuteCultureData: {},
  embryoCultureTabList: [],
  selectedOocyteDishSample: null,
  selectedEmbryoDishSample: null,
  selectedOocyteItem: {},
  selectedEmbryoGroup: {},
  executeCultureEmbryoList: [],
  globalEmbryoMediaList: [],
  isTimerRunning: false,
  strawListForEmbryos: [],
  embryoStrawCollectionList: [],

};

export const registerSlice = createSlice({
  name: "Pages",
  initialState,
  reducers: {
    //Embryo
    setProcedureOocytesList: (state, action) => {
      state.procedureOocytesList = action.payload;
    },
    setEmbryoDayTabCounter: (state, action) => {
      state.embryoDayTabCounter = action.payload;
    },
    setSelectedOocyteDishSample: (state, action) => {
      state.selectedOocyteDishSample = action.payload;
    },
    setSelectedEmbryoDishSample: (state, action) => {
      state.selectedEmbryoDishSample = action.payload;
    },
    setSelectedOocyteItem: (state, action) => {
      state.selectedOocyteItem = action.payload;
    },
    setSelectedEmbryoGroup: (state, action) => {
      state.selectedEmbryoGroup = action.payload;
    },
    setEmbryoCultureTabList: (state, action) => {
      state.embryoCultureTabList = action.payload;
    },
    setEmbryoExecuteCultureData: (state, action) => {
      state.embryoExecuteCultureData = action.payload;
    },
    setExecuteCultureEmbryoList: (state, action) => {
      state.executeCultureEmbryoList = action.payload;
    },
    setIsTimerRunning: (state, action) => {
      state.isTimerRunning = action.payload;
    },
    setGlobalEmbryoMediaList: (state, action) => {
      state.globalEmbryoMediaList = action.payload;
    },
    setStrawListForEmbryos: (state, action) => {
      state.strawListForEmbryos = action.payload;
    },
    setEmbryoStrawCollectionList: (state, action) => {
      state.embryoStrawCollectionList = action.payload;
    },


    //---------
    rgisterPagesRender: (state, action) => {
      state.value = action.payload;
    },
    patientId: (state, action) => {
      state.patientId = action.payload;
    },
    setRegisteredPatientData: (state, action) => {
      state.registeredPatientData = action.payload;
    },
    setDefineLayer: (state, action) => {
      state.defineLayer = action.payload;
    },
    hospitalId: (state, action) => {
      state.hospitalId = action.payload;
    },
    patientWho: (state, action) => {
      state.whoData = action.payload;
    },
    patientContact: (state, action) => {
      state.contactData = action.payload;
    },
    patientChoice: (state, action) => {
      state.choiceData = action.payload;
    },
    setStockPermissions: (state, action) => {
      state.stockPermission = action.payload;
    },
    setClinicCountryCode: (state, action) => {
      state.clinicCountryCode = action.payload;
    },
    setCryoPermission: (state, action) => {
      state.cryoPermission = action.payload;
    },
    setAndroPermission: (state, action) => {
      state.androPermission = action.payload;
    },
    setDewarListData: (state, action) => {
      state.dewarListData = action.payload;
    },
    setRegisterDewarSelected: (state, action) => {
      state.registerDewarSelected = action.payload
    },
    setCurrentSelectedLevel: (state, action) => {
      state.currentSelectedLevel = action.payload;
    },
    setChangeLocationSelectedLevel: (state, action) => {
      state.changeLocationSelectedLevel = action.payload;
    },
    setOptions: (state, action) => {
      state.options = action.payload;
    },
    setSelectedSliceObject: (state, action) => {
      state.selectedSliceObject = action.payload;
    },
    setSelectedSliceInputError: (state, action) => {
      state.selectedSliceInputError = action.payload;
    },
    setSelectedCanLevelObject: (state, action) => {
      state.selectedCanLevelObject = action.payload;
    },
    setSelectedCanForStorageSpace: (state, action) => {
      state.selectedCanForStorageSpace = action.payload;
    },
    setCanLevelAddToggle: (state, action) => {
      state.canLevelAddToggle = action.payload;
    },
    setCanPackageAddToggle: (state, action) => {
      state.canPackageAddToggle = action.payload;
    },
    setCanFirstLayerToggle: (state, action) => {
      state.canFirstLayerToggle = action.payload;
    },
    setCanLayerData: (state, action) => {
      state.canLayerData = action.payload;
    },
    setParentNodeDetails: (state, action) => {
      state.parentNodeDetails = action.payload;
    },
    setNodeDetails: (state, action) => {
      state.nodeDetails = action.payload;
    },
    setHasCanisterItem: (state, action) => {
      state.hasCanisterItem = action.payload;
    },
    setLastSelectedLayerDetail: (state, action) => {
      state.lastSelectedLayerDetail = action.payload;
    },
  },
});

export const {
  rgisterPagesRender,
  patientId,
  hospitalId,
  setRegisteredPatientData,
  patientWho,
  setDefineLayer,
  patientContact,
  patientChoice,
  setStockPermissions,
  setCryoPermission,
  setAndroPermission,
  setDewarListData,
  setRegisterDewarSelected,
  setCurrentSelectedLevel,
  setChangeLocationSelectedLevel,
  setOptions,
  setSelectedSliceObject,
  setSelectedCanForStorageSpace,
  setSelectedSliceInputError,
  setSelectedCanLevelObject,
  setCanLevelAddToggle,
  setCanPackageAddToggle,
  setCanFirstLayerToggle,
  setCanLayerData,
  setParentNodeDetails,
  setNodeDetails,
  setHasCanisterItem,
  setLastSelectedLayerDetail,
  setClinicCountryCode,

  //embryo
  setProcedureOocytesList,
  setEmbryoDayTabCounter,
  setSelectedOocyteDishSample,
  setSelectedEmbryoDishSample,
  setEmbryoCultureTabList,
  setEmbryoExecuteCultureData,
  setSelectedOocyteItem,
  setSelectedEmbryoGroup,
  setExecuteCultureEmbryoList,
  setIsTimerRunning,
  setGlobalEmbryoMediaList,
  setStrawListForEmbryos,
  setEmbryoStrawCollectionList
} = registerSlice.actions;
export default registerSlice.reducer;
