import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allTemplates: null,
  reloadTemplates: true,
  selectedTemplate: null,
  investigationTab: false,
  procedureTab: false,
  selectedTest: null,
  reloadTestsPosition: true,
  closeTemplate: false,
};

const IAndPSlice = createSlice({
  name: "IAndPSlice",
  initialState: initialState,
  reducers: {
    setAllTemplates: (state, action) => {
      state.allTemplates = action.payload;
    },
    refetchTemplates: (state, action) => {
      state.reloadTemplates = action.payload;
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload ? action.payload.item : null;
      state.closeTemplate = action.payload ? action.payload.open : false;
    },
    blinktab: (state, action) => {
      state.investigationTab = action.payload.investigationTab;
      state.procedureTab = action.payload.procedureTab;
    },
    setSelectedTest: (state, action) => {
      state.selectedTest = action.payload
    },
    refetchTestDetail: (state, action) => {
      state.reloadTestsPosition = action.payload;
    },
  }
})

export const {
  setAllTemplates,
  refetchTemplates,
  setSelectedTemplate,
  blinktab,
  setSelectedTest,
  refetchTestDetail,
} = IAndPSlice.actions;
export default IAndPSlice.reducer;