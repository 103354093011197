import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUrl: "",
};

export const popupSlice = createSlice({
  name: "ShowAPi",
  initialState,
  reducers: {
    setCurrentUrl: (state, action) => {
      state.currentUrl = action.payload;
    },
  },
});

export const { setCurrentUrl } = popupSlice.actions;
export default popupSlice.reducer;
