import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  userPictureURL: null,
  firstName: "",
  lastName: "",
}

const userSlice = createSlice({
  name: "userSlice",
  initialState: initialState,
  reducers: {
    setProfileImage: (state, action) => {
      state.userPictureURL = action.payload;
    },
    setProfileName: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    }
  }
})

export const { setProfileImage, setProfileName } = userSlice.actions;
export default userSlice.reducer;