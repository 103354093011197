import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { CssBaseline } from "@mui/material";
import store from "./app/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import loader from "./assets/gif/loading.gif";

const App = lazy(() => import("./App"));
const Loader = () => (
  <div
    style={{
      width: "max-content",
      height: "max-content",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <img
      src={loader}
      alt="loading"
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "300px",
      }}
    />
  </div>
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<Loader />}>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <CssBaseline />
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
