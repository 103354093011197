import { configureStore } from "@reduxjs/toolkit";
import registerSlice from "./services/registerSlice";
import resShowApi from "./services/resShowApi";
import modalSlice from "./services/modalSlice";
import IAndPSlice from "./services/IAndPSlice";
import userSlice from "./services/userSlice";
import popupSlice from "./services/popupSlice";

const store = configureStore({
  reducer: {
    render: registerSlice,
    resPonse: resShowApi,
    modal: modalSlice,
    iandp: IAndPSlice,
    userDetails: userSlice,
    popup: popupSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
