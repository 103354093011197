import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {},
};

export const resShowApi = createSlice({
  name: "ShowAPi",
  initialState,
  reducers: {
    storeResponse: (state, action) => {
      state.value = action.payload;
    },
    clearValue: (state, action) => {
      state.value = action.payload;

      // state.value = action.payload;
    },
  },
});

export const { storeResponse, clearValue } = resShowApi.actions;
export default resShowApi.reducer;
